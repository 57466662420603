<template>
  <el-container v-if="showPaper">
    <el-header>
      <el-row>
        <el-col style="border-bottom: 1px solid #f5f5f5">
          <el-row>
            <span class="examTitle">试卷名字: </span>
            <span style="color: red;font-size: 18px;">{{ examInfo.examName }}</span>
            <span class="examTitle">试卷总分: </span>
            <span style="color: red;font-size: 18px;">{{ examInfo.paperScore }} 分</span>
            <span class="examTitle">考试时间: </span>
            <span style="color: red;font-size: 18px;">{{ examInfo.examTime }}</span>
          </el-row>
          <el-row>
            <span class="examTitle">总分: </span>
            <span style="color: red;font-size: 18px;">{{ examInfo.userScore }} 分</span>
            <span class="examTitle">客观题得分: </span>
            <span style="color: red;font-size: 18px;">{{ examInfo.objectiveScore }} 分</span>
            <span class="examTitle">主观题得分: </span>
            <span style="color: red;font-size: 18px;">{{ examInfo.subjectiveScore }} 分</span>
          </el-row>
          <el-row>
            <div v-if="paperStatus === 3">
              <el-button
                type="warning"
                round
                style="background-color: #ffd550;float: right;color: black;font-weight: 800"
                @click="uploadExamToAdmin"
              >提交阅卷
              </el-button>
            </div>
          </el-row>
        </el-col>
      </el-row>
    </el-header>

    <el-main>
      <el-row>
        <el-col :span="16">
          <el-row v-for="(item, index) in questionResults" :key="index">
            <el-row class="card-style">
              <el-card>
                <!-- 题目信息 -->
                <div>
                  <span v-if="item.questionInfo.questionType === 1">【单选题】</span>
                  <span v-else-if="item.questionInfo.questionType === 2">【多选题】</span>
                  <span v-else-if="item.questionInfo.questionType === 3">【不定项选择题】</span>
                  <span v-else-if="item.questionInfo.questionType === 4">【判断题】</span>
                  <span v-else-if="item.questionInfo.questionType === 5">【填空题】</span>
                  <span v-else-if="item.questionInfo.questionType === 6">【问答题】</span>
                  <span v-else-if="item.questionInfo.questionType === 7">【理解题】</span>
                  <span v-else>【综合题】</span>
                  <br>
                  <br>
                  <i class="num">{{ item.questionInfo.pos }}</i>
                  <span style="color: red">
                    ({{ item.questionInfo.score }} 分)
                  </span>
                  <span v-html="item.questionInfo.questionContent" />
                  <span style="color: green">
                    (得分: {{ item.userScore }} 分)
                  </span>
                  <div v-if="item.questionInfo.questionType !== 8">
                    <el-button
                      type="warning"
                      size="mini"
                      icon="el-icon-info"
                      @click="questionAnalysisDialog = true"
                    >试题解析</el-button>
                  </div>
                  <div v-if="item.questionInfo.questionType === 8">
                    <div v-for="(child, childIndex) in item.children" :key="childIndex">
                      <div
                        style="margin-top: 40px"
                      >
                        <span style="color: red">
                          ({{ child.questionInfo.score }} 分)
                        </span>
                        <span v-html="child.questionInfo.questionContent" />
                        <span style="color: green">
                          (得分: {{ child.userScore }} 分)
                        </span>
                        <el-button
                          type="warning"
                          size="mini"
                          icon="el-icon-info"
                          @click="questionAnalysisDialog = true"
                        >试题解析</el-button>
                      </div>
                      <!-- 单选和判断题候选答案列表 -->
                      <div
                        v-show="child.questionInfo.questionType === 1 || child.questionInfo.questionType === 4"
                        style="margin-top: 25px"
                      >
                        <el-radio-group
                          v-for="(ans,ans_index) in child.questionInfo.answer"
                          :key="ans_index"
                          v-model="child.userAnswer"
                          text-color="green"
                        >
                          <el-radio-button
                            :label="ans.pos"
                            disabled
                          >
                            <span
                              :class="ans.correct ? 'el-icon-check' : ''"
                            >
                              {{ optionName[ans_index] + '、' + ans.answer }}
                            </span>
                          </el-radio-button>
                        </el-radio-group>
                        <div style="margin-top: 25px">
                          <el-divider />
                          <span>答案解析</span>
                        </div>
                      </div>

                      <!-- 多选和不定项选择题的候选答案列表 -->
                      <div
                        v-show="child.questionType === 2 || child.questionType === 3"
                        style="margin-top: 25px"
                      >
                        <el-checkbox-group
                          v-for="(ans,ans_index) in child.answer"
                          :key="ans_index"
                          v-model="child.userAnswer"
                          text-color="green"
                        >
                          <el-checkbox :label="ans_index">
                            <span
                              :class="ans.correct ? 'el-icon-check' : ''"
                            >
                              {{ optionName[ans_index] + '、' + ans.answer }}
                            </span>
                          </el-checkbox>
                        </el-checkbox-group>
                        <div style="margin-top: 25px">
                          <span>答案解析</span>
                        </div>
                      </div>

                      <!-- 填空题和问答题的回答区 -->
                      <div
                        v-show="child.questionType === 5 || child.questionType === 6"
                        style="margin-top: 25px"
                      >
                        <el-input
                          v-model="userAnswer[curIndex]"
                          type="textarea"
                          :rows="8"
                          placeholder="请输入答案"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 单选和判断题候选答案列表 -->
                <div
                  v-show="item.questionInfo.questionType === 1 || item.questionInfo.questionType === 4"
                  style="margin-top: 25px"
                >
                  <el-radio-group
                    v-for="(ans,ans_index) in item.questionInfo.answer"
                    :key="ans_index"
                    v-model="item.userAnswer"
                    text-color="green"
                  >
                    <el-radio-button
                      :label="ans.pos"
                      disabled
                    >
                      <span
                        :class="ans.correct ? 'el-icon-check' : ''"
                      >
                        {{ optionName[ans_index] + '、' + ans.answer }}
                      </span>
                    </el-radio-button>
                  </el-radio-group>
                  <div style="margin-top: 25px">
                    <span>答案解析</span>
                  </div>
                </div>

                <!-- 多选和不定项选择题的候选答案列表 -->
                <div
                  v-show="item.questionInfo.questionType === 2 || item.questionInfo.questionType === 3"
                  style="margin-top: 25px"
                >
                  <el-checkbox-group
                    v-for="(ans,ans_index) in item.questionInfo.answer"
                    :key="ans_index"
                    v-model="item.userAnswer"
                    text-color="green"
                  >
                    <el-checkbox-button
                      :label="ans.pos"
                      border
                      style="margin-top: 20px;"
                      disabled
                    >
                      <span
                        :class="ans.correct ? 'el-icon-check' : ''"
                      >
                        {{ optionName[ans_index] + '、' + ans.answer }}
                      </span>
                    </el-checkbox-button>
                  </el-checkbox-group>
                  <div style="margin-top: 25px">
                    <el-divider />
                    <span>答案解析</span>
                  </div>
                </div>

                <!-- 填空题的回答区 -->
                <div
                  v-show="item.questionInfo.questionType === 5"
                  style="margin-top: 25px"
                >
                  <div style="margin-top: 25px">
                    <el-row>
                      <span>用户答案:</span>
                    </el-row>
                    <el-row>
                      <span style="color: green" v-html="item.userAnswer" />
                    </el-row>
                  </div>
                  <div style="margin-top: 25px">
                    <el-row>
                      <span>正确答案:</span>
                    </el-row>
                    <el-row>
                      <span style="color: orangered" v-html="item.correctAnswer" />
                    </el-row>
                  </div>
                  <div v-if="paperStatus === 3" style="margin-top: 25px">
                    <span>评分:</span>
                    <el-input-number
                      v-model="questionMarks[item.questionInfo.pos].score"
                      :min="0"
                      :max="50"
                      @change="onInputNumber(item.questionInfo)"
                    />
                    <el-input
                      v-model="questionMarks[item.questionInfo.pos].review"
                      type="textarea"
                      :rows="3"
                      placeholder="请输入评语"
                      @blur="onInputBlur(item.questionInfo)"
                    />
                  </div>
                  <div v-if="paperStatus === 4" style="margin-top: 25px">
                    <span>
                      得分: <span style="color: green">{{ questionMarks[item.questionInfo.pos].score }}</span>
                    </span>
                    <br/>
                    <br/>
                    <span>
                      评语: <span style="color: green">{{ questionMarks[item.questionInfo.pos].review }}</span>
                    </span>
                  </div>
                  <div style="margin-top: 25px">
                    <el-divider />
                    <span>答案解析</span>
                  </div>
                </div>
                <!-- 问答题的回答区 -->
                <div
                  v-show="item.questionInfo.questionType === 6"
                  style="margin-top: 25px"
                >
                  <div style="margin-top: 25px">
                    <el-row>
                      <span>用户答案:</span>
                    </el-row>
                    <el-row>
                      <span style="color: green" v-html="item.userAnswer" />
                    </el-row>
                  </div>
                  <div style="margin-top: 25px">
                    <el-row>
                      <span>正确答案:</span>
                    </el-row>
                    <el-row>
                      <span style="color: orangered" v-html="item.correctAnswer" />
                    </el-row>
                  </div>
                  <div v-if="paperStatus === 3" style="margin-top: 25px">
                    <span>评分:</span>
                    <el-input-number
                      v-model="questionMarks[item.questionInfo.pos].score"
                      :min="0"
                      :max="50"
                      @change="onInputNumber(item.questionInfo)"
                    />
                    <el-input
                      v-model="questionMarks[item.questionInfo.pos].review"
                      type="textarea"
                      :rows="3"
                      placeholder="请输入评语"
                      @blur="onInputBlur(item.questionInfo)"
                    />
                  </div>
                  <div v-if="paperStatus === 4" style="margin-top: 25px">
                    <span>
                      得分: <span style="color: green">{{ questionMarks[item.questionInfo.pos].score }}</span>
                    </span>
                    <br/>
                    <br/>
                    <span>
                      评语: <span style="color: green">{{ questionMarks[item.questionInfo.pos].review }}</span>
                    </span>
                  </div>
                  <div style="margin-top: 25px">
                    <el-divider />
                    <span>答案解析</span>
                  </div>
                </div>
              </el-card>
            </el-row>
          </el-row>
        </el-col>
        <!-- 答题卡 -->
        <el-col :span="8">
          <el-row class="card-style">
            <el-card>
              <div slot="header" class="clearfix">
                <h1>阅卷须知</h1>
              </div>
              <div class="text item">
                <el-row>
                  <h3>客观题部分</h3>
                  <span>
                    系统已自动对客观题进行了阅卷<br>
                  </span>
                  <span>
                    用户答案使用<span style="color: green">绿色</span>文字标记<br>
                  </span>
                  <span>
                    参考答案前面会使用 <span class="el-icon-check" /> 来标记<br>
                  </span>
                </el-row>
                <el-row>
                  <h3>主观题部分</h3>
                  <span>
                    用户答案使用<span style="color: green">绿色</span>文字标记<br>
                  </span>
                  <span>
                    参考答案使用<span style="color: orangered">红色</span>文字标记<br>
                  </span>
                </el-row>
              </div>
            </el-card>
          </el-row>
          <el-row class="card-style">
            <el-card>
              <div>
                <p style="font-size: 18px;">答题卡</p>
              </div>
              <!-- 单选的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">单选题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 1"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 1 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                >{{ item }}
                </el-button>
              </div>
              <!-- 多选的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">多选题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 2"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                >{{ item }}
                </el-button>
              </div>
              <!-- 不定项选择题的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">不定项选择题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 3"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 3 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                >{{ item }}
                </el-button>
              </div>

              <!-- 判断题的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">判断题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 4"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 4 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                >{{ item }}
                </el-button>
              </div>

              <!-- 填空题的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">填空题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 5"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 5 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                >{{ item }}
                </el-button>
              </div>

              <!-- 问答题的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">问答题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 6"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 6 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                >{{ item }}
                </el-button>
              </div>

              <!-- 综合题的答题卡 -->
              <div style="margin-top: 25px">
                <p style="font-size: 18px;">综合题</p>
                <el-button
                  v-for="item in questionList.length"
                  v-show="questionList[item-1].questionType === 8"
                  :key="item"
                  style="margin-top: 10px;margin-left: 15px"
                  size="mini"
                  :class="questionList[item-1].questionType === 8 && userAnswer[item-1] !== undefined ?
                    'done' : userAnswer[item-1] === undefined ? curIndex === (item-1) ? 'orange' : 'noAnswer' : 'noAnswer'"
                  @click="curIndex = item-1"
                >{{ item }}
                </el-button>
              </div>
            </el-card>
          </el-row>
        </el-col>
      </el-row>
    </el-main>

    <el-dialog :visible.sync="questionAnalysisDialog" @close="questionAnalysisDialog = false">
      <span>试题解析</span>
    </el-dialog>
  </el-container>
</template>

<script>
import { getExamResult, getPaperQuestions, submitExamMark } from '@/api/exam'

export default {
  name: 'ExamPaperResult',
  data() {
    return {
      paperStatus: 1,
      questionAnalysisDialog: false,
      // 当前考试的信息
      examInfo: {},
      // 当前的考试题目
      questionList: [
        {
          questionType: ''
        }
      ],
      // 当前题目的索引值
      curIndex: 0,
      // 控制大图的对话框
      bigImgDialog: false,
      // 当前要展示的大图的url
      bigImgUrl: '',
      // 用户选择的答案
      userAnswer: [],
      // 页面数据加载
      loading: {},
      // 页面绘制是否开始
      showPaper: false,
      // 答案的选项名abcd数据
      optionName: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      // 考试总时长
      duration: 0,
      answer: {
        pos: null,
        questionId: null,
        questionType: null,
        questionAnswer: null,
        children: []
      },
      questionMap: new Map(),
      childMap: new Map(),
      questionResults: [],
      questionMarks: [
        { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' },
        { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' },
        { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' },
        { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' },
        { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' },
        { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' },
        { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' },
        { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' },
        { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' },
        { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' },
        { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }, { score: 0, review: '' }
      ]
    }
  },
  created() {
    const path = this.$route.path
    if (path.startsWith('/exam/mark')) {
      document.title = '试卷评判'
      this.paperStatus = 3
      const resultId = this.$route.params.resultId
      this.getExamResult(resultId)
    } else if (path.startsWith('/exam/score')) {
      document.title = '试卷成绩'
      this.paperStatus = 4
      const resultId = this.$route.params.resultId
      this.getExamResult(resultId)
    }

    const paperId = this.$route.params.paperId
  },
  methods: {
    renderByMathjax() {
      // tinymce 的 mathjax 插件生成的 latex 格式公式放在 className 为 math-tex 的 span 标签内
      const className = 'math-tex'
      this.$nextTick(function() {
        if (this.MathJax.isMathjaxConfig) {
          this.MathJax.initMathjaxConfig()
        }
        this.MathJax.MathQueue1(className)
      })
    },
    getExamResult(resultId) {
      getExamResult(resultId).then((resp) => {
        if (resp.code === 0) {
          const respData = resp.data
          this.examInfo = {
            examId: respData.examId,
            resultId: respData.resultId,
            examName: respData.examName,
            paperScore: respData.paperScore,
            examTime: respData.examTime,
            userScore: respData.userScore,
            objectiveScore: respData.objectiveScore,
            subjectiveScore: respData.subjectiveScore
          }
          this.questionResults = resp.data.questionResults
          const paperId = resp.data.examId
          this.getQuestionInfo1(paperId)
        }
      })
    },
    // 查询考试的题目信息
    getQuestionInfo1(paperId) {
      getPaperQuestions(paperId).then(resp => {
        if (resp.code === 0) {
          this.questionList = resp.data || []
          // 重置问题的顺序 单选 ->  -> 判断 -> 简答
          this.questionList = this.questionList.sort(function(a, b) {
            return a.questionType - b.questionType
          })

          for (const item of this.questionList) {
            if (item.questionType === 5 || item.questionType === 6) {
              this.questionMap.set(item.questionId, item)
            }

            if (item.children !== undefined && item.children !== null) {
              for (const child of item.children) {
                if (child.questionType === 5 || child.questionType === 6) {
                  this.childMap.set(child.questionId, item.questionId)
                  this.questionMap.set(item.questionId, item)
                }
              }
            }
          }
          this.showPaper = true
          this.renderByMathjax()
        }
      })
    },
    // ****************************************************************************************************************
    // 填空和问答题
    onInputNumber(val) {
      console.log(this.questionMarks[val.pos])
    },
    onInputBlur(val) {
      console.log(this.questionMarks[val.pos])
    },
    async uploadExamToAdmin() {
      var submitMarks = []
      for (const item of this.questionMap) {
        const question = item[1]
        const pos = question.pos
        const markForm = this.questionMarks[pos]
        submitMarks.push({
          questionId: question.questionId,
          pos: pos,
          score: markForm.score,
          review: markForm.review
        })
      }

      const markResult = {}
      markResult.paperId = this.examInfo.examId
      markResult.resultId = this.examInfo.resultId
      markResult.paperMarks = submitMarks
      submitExamMark(markResult).then((resp) => {
        if (resp.code === 0) {
          this.$notify({
            title: 'Tips',
            message: '阅卷结果已提交',
            type: 'success',
            duration: 2000
          })
          this.$router.push('/exam/mark')
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    showCorrectAnswer(correctAnswer) {
      this.$message.info(correctAnswer)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  font-weight: 800;
}

.el-container {
  width: 100%;
  height: 100%;
}

.startExam {
  color: #160f58;
  border-bottom: 4px solid #ffd550;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 10px
}

.examTitle {
  font-size: 18px;
  color: #cbcacf;
  margin-left: 20px;
  font-weight: 700;
}

.el-radio-group label {
  display: block;
  width: 400px;
  padding: 48px 20px 10px 20px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
  }
}

.el-radio-group label:hover {
  background-color: rgb(245, 247, 250);
}

/*当前选中的答案*/
.active {
  border: 1px solid #1f90ff !important;
  opacity: .5;
}

/*做过的题目的高亮颜色*/
.done {
  background-color: rgb(87, 148, 247);
}

/*未做题目的高亮颜色*/
.noAnswer {
  background-color: rgb(238, 238, 238);
}

/*当前在做的题目高亮的颜色*/
.orange {
  background-color: rgb(255, 213, 80);
}

.num {
  display: inline-block;
  background: url('../../assets/img/examTitle.png') no-repeat 95%;
  background-size: contain;
  height: 37px;
  width: 37px;
  line-height: 30px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin-right: 15px;
}

.card-style {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
